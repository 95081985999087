import { FaChevronLeft } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { UseItemResult } from '../services/useItem';
import { Gender } from '../utils/format';
import { CenterSpinner } from './CenterSpinner';
import { DeleteButton } from './DeleteButton';
import { ErrorAlert } from './ErrorAlert';
import { CreatorUpdater } from './forms/CreatorUpdater';

const newLabel: { [key in Gender]: string } = {
  [Gender.Masculine]: 'Новый',
  [Gender.Feminine]: 'Новая',
  [Gender.Neuter]: 'Новое',
};

interface EntityHeaderProps<T> {
  data: UseItemResult<T>;
  del?: boolean;
  gender?: Gender;
  getTitle?: (item: T) => string | JSX.Element;
  label?: string;
  listLabel?: string;
  listUrl?: string;
  readOnly?: boolean;
}

export function EntityHeader<T>({
  children,
  data,
  del = true,
  gender = Gender.Masculine,
  getTitle,
  label,
  listLabel,
  listUrl,
}: React.PropsWithChildren<EntityHeaderProps<T>>) {
  const { creating, error, found, item, refreshing, remove } = data;

  if (refreshing) {
    return <CenterSpinner />;
  }

  return (
    <>
      <div className="d-flex flex-row align-items-center">
        {listUrl && (
          <Link
            className="btn btn-sm btn-secondary mr-2"
            to={(l) => ({ ...l, pathname: listUrl })}
          >
            <FaChevronLeft /> {listLabel}
          </Link>
        )}
        {children}
        <div className="ml-auto"></div>
        {del && <DeleteButton onClick={remove} />}
      </div>

      <h2 className="mt-3">
        <div>
          <small className="text-muted">{label}</small>
        </div>
        {(item && getTitle && getTitle(item)) || (creating && newLabel[gender])}
      </h2>

      <CreatorUpdater gender={gender} {...item} />

      <ErrorAlert>{error}</ErrorAlert>

      {found && <hr />}
    </>
  );
}
