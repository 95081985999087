import classNames from 'classnames';
import classnames from 'classnames';
import { FaPlus } from 'react-icons/fa';
import { Link } from 'react-router-dom';

import { UseItemsResult } from '../services/useItems';
import { CenterSpinner } from './CenterSpinner';
import { ErrorAlert } from './ErrorAlert';
import { Paginator } from './Paginator';

interface EntityListHeaderProps<T> {
  addLabel?: string;
  children?: React.ReactNode;
  createUrl?: string;
  data: UseItemsResult<T>;
  onPageClick?: (page: number) => void;
  title?: string;
}

export function EntityListHeader<T>({
  addLabel,
  children,
  createUrl,
  data,
  onPageClick,
  title,
}: EntityListHeaderProps<T>) {
  const { error, notFound, pageCount, refreshing, total } = data;
  const showPagination = pageCount > 1 && !!onPageClick;
  return (
    <div className="mb-3">
      <div className="d-flex align-items-center flex-wrap">
        {title && <h2>{title}</h2>}
        {!refreshing && !error && (
          <>
            <div className="text-muted mx-3">{total}</div>
            {children}
            <div className={classnames(!children && 'ml-auto')}>
              {createUrl && (
                <Link
                  role="button"
                  to={createUrl}
                  className={classNames(
                    'btn',
                    'btn-sm',
                    'btn-secondary',
                    showPagination && 'mr-3'
                  )}
                >
                  <FaPlus /> {addLabel || 'Создать'}
                </Link>
              )}
            </div>
            {showPagination && (
              <Paginator
                className={classNames('my-2')}
                page={data.page}
                pageCount={data.pageCount}
                onPageClick={onPageClick}
              />
            )}
          </>
        )}
      </div>

      <ErrorAlert>{error}</ErrorAlert>

      {refreshing && <CenterSpinner />}

      {notFound && title && <div className="mt-3">{title} не найдены</div>}
    </div>
  );
}
