import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { Paginator } from '../components/Paginator';
import { useItems } from '../services/useItems';
import type { EmailTemplate as EmailTemplateType } from './EmailTemplateTypes';

export const EmailTemplates: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<EmailTemplateType>(
    '/email-templates',
    history,
    location
  );
  const { found, data, onPageClick, onRowClick, page, pageCount } = list;

  return (
    <>
      <EntityListHeader
        data={list}
        title="Шаблоны писем"
        createUrl="/email-templates/create"
        onPageClick={onPageClick}
      />

      {found && (
        <div className="table-responsive">
          <table className="table table-hover table-list">
            <thead>
              <tr>
                <th>ID</th>
                <th>Название</th>
                <th>Тема письма</th>
                <th>Изменен</th>
              </tr>
            </thead>
            <tbody>
              {data.map((i) => (
                <tr key={i.id} onClick={onRowClick}>
                  <td>
                    <Link to={`/email-templates/${i.id}`}>{i.id}</Link>
                  </td>
                  <td>{i.name}</td>
                  <td>{i.subject}</td>
                  <td>{i.updated_at.fromNow()}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="float-right">
        {pageCount > 1 && (
          <Paginator
            page={page}
            onPageClick={onPageClick}
            pageCount={pageCount}
          />
        )}
      </div>
    </>
  );
};
