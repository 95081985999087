import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';

import { EntityHeader } from '../components/EntityHeader';
import { ItemHistory } from '../guests/ItemHistory';
import { useItem } from '../services/useItem';
import { StaffForm } from './StaffForm';
import { StaffPerson } from './StaffTypes';
import { validateStaff } from './validateStaff';

export const StaffItem: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<StaffPerson>(match.params.id, '/staff', history);
  const { found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={() => 'Волонтёр'}
        listLabel="Волонтёры"
        listUrl="/staff"
      >
        {found && item && <ItemHistory item={item} url="staff" />}
      </EntityHeader>

      {found && (
        <Form
          initialValues={item}
          onSubmit={save}
          render={StaffForm}
          validate={validateStaff}
        />
      )}
    </>
  );
};
