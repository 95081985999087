import moment from 'moment';

import { Event } from './EventTypes';

export const validateEvent = (values: Event) => {
  const errors: { [key: string]: string } = {};
  if (!values.title) {
    errors.title = 'Нужно заполнить';
  }
  if (!values.starts_at) {
    errors.starts_at = 'Нужно заполнить';
  }
  if (!values.ends_at) {
    errors.ends_at = 'Нужно заполнить';
  }
  if (!moment(values.starts_at).isBefore(values.ends_at)) {
    errors.ends_at = 'Должно быть после времени начала';
  }
  if (!values.group) {
    errors.group = 'Нужно выбрать';
  }
  return errors;
};
