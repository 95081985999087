import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router';

import { useSearch } from './useSearch';

export type Order<T extends {}> = [keyof T, boolean];

export const useOrder = <T>(
  initial?: Order<T>
): [
  Order<T> | undefined,
  (col: keyof T) => () => void,
  (col: keyof T) => React.ReactNode
] => {
  const history = useHistory();
  const location = useLocation();
  const [params, setParams] = useSearch({ history, location });

  const po = typeof params.order === 'string' ? params.order : undefined;

  const order = useMemo<Order<T> | undefined>(() => {
    if (!po) {
      return initial;
    }
    if (po.startsWith('-')) {
      return [po.substr(1) as keyof T, false];
    }
    return [po as keyof T, true];
  }, [initial, po]);

  const setOrder = useCallback(
    (v: React.SetStateAction<Order<T> | undefined>) => {
      const next = typeof v === 'function' ? v(order) : v;

      setParams(({ order: _, ...p }) =>
        !next ||
        (initial && next && next[0] === initial[0] && next[1] === initial[1])
          ? p
          : { ...p, order: next[1] ? `${next[0]}` : `-${next[0]}` }
      );
    },
    [initial, setParams, order]
  );

  const toggleOrder = useCallback(
    (col: keyof T) => () => {
      setOrder((v) => [col, v && v[0] === col ? !v[1] : true]);
    },
    [setOrder]
  );

  const getOrder = useCallback(
    (col: keyof T) => {
      const v = order || initial;
      if (!v || col !== v[0]) {
        return null;
      }
      return v[1] ? ' ↑' : ' ↓';
    },
    [initial, order]
  );

  return [order, toggleOrder, getOrder];
};
