import classnames from 'classnames';
import { useState } from 'react';
import { FaTimes } from 'react-icons/fa';

type BtnSize = 'sm' | 'md' | 'lg';

interface DeleteButtonProps {
  onClick: () => void;
  size?: BtnSize;
  refreshing?: boolean;
}

const btnSizeClass: Record<BtnSize, string | null> = {
  sm: 'btn-sm',
  md: null,
  lg: 'btn-lg',
};

export const DeleteButton: React.FC<DeleteButtonProps> = ({
  size = 'sm',
  refreshing,
  onClick,
}) => {
  const [visible, setVisible] = useState(false);
  return (
    <>
      {!visible && (
        <button
          type="button"
          disabled={refreshing}
          className={classnames('btn', 'btn-link', btnSizeClass[size])}
          onClick={() => setVisible(true)}
        >
          Удалить
        </button>
      )}
      {visible && (
        <button
          type="button"
          disabled={refreshing}
          className={classnames(
            'btn',
            'btn-danger',
            'mr-2',
            btnSizeClass[size]
          )}
          onClick={onClick}
        >
          <FaTimes /> Удалить
        </button>
      )}
      {visible && ' '}
      {visible && (
        <button
          type="button"
          disabled={refreshing}
          className={classnames('btn', 'btn-secondary', btnSizeClass[size])}
          onClick={() => setVisible(false)}
        >
          Отмена
        </button>
      )}
    </>
  );
};
