import { useState, useCallback } from 'react';

import './Login.css';

interface LoginProps {
  error?: string;
  onSubmit: (username: string, password: string) => void;
  refreshing?: boolean;
  title: string;
}

export const Login: React.FC<LoginProps> = ({
  error,
  onSubmit,
  refreshing = false,
  title,
}) => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');

  const onFormSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmit(username, password);
    },
    [onSubmit, username, password]
  );

  return (
    <div className="signin-container">
      <form className="form-signin" onSubmit={onFormSubmit}>
        <h1 className="h3 mb-3 font-weight-normal">
          <span style={{ zIndex: 1, position: 'relative' }}>{title}</span>
        </h1>
        <label htmlFor="inputUsername" className="sr-only">
          Логин
        </label>
        <input
          type="text"
          id="inputUsername"
          className="form-control"
          placeholder="Логин"
          autoFocus
          value={username}
          onChange={useCallback((e) => setUsername(e.target.value), [])}
        />
        <label htmlFor="inputPassword" className="sr-only">
          Пароль
        </label>
        <input
          type="password"
          id="inputPassword"
          className="form-control"
          placeholder="Пароль"
          value={password}
          onChange={useCallback((e) => setPassword(e.target.value), [])}
        />
        <button
          className="btn btn-lg btn-primary btn-block"
          type="submit"
          disabled={refreshing || !username || !password}
        >
          Войти
        </button>

        {error && (
          <div className="alert alert-danger" style={{ marginTop: 25 }}>
            {error}
          </div>
        )}
      </form>
    </div>
  );
};
