import { Field } from 'react-final-form';

import { FormGroup } from './FormGroup';

interface TextAreaProps {
  cols?: number;
  help?: string;
  label?: string;
  labelCols?: number;
  name: string;
}

export const TextArea: React.FC<
  TextAreaProps & React.HTMLProps<HTMLTextAreaElement>
> = ({ cols = 10, help, label, labelCols = 2, name, ...rest }) => (
  <Field name={name}>
    {({ input }) => (
      <FormGroup cols={cols} label={label} id={name} labelCols={labelCols}>
        <textarea className="form-control" id={name} {...input} {...rest} />
        {help && <small className="form-text text-muted">{help}</small>}
      </FormGroup>
    )}
  </Field>
);
