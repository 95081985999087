import { useFormContext } from './Form';

interface FormGroupProps {
  cols?: number;
  error?: string;
  id?: string;
  label?: string;
  labelCols?: number;
}

export const FormGroup: React.FC<FormGroupProps> = ({
  children,
  cols = 10,
  id,
  label,
  labelCols = 2,
}) => {
  const { horizontal } = useFormContext();
  return (
    <div className={`form-group row`}>
      <label
        className={
          horizontal ? `col-sm-${labelCols} col-form-label` : undefined
        }
        htmlFor={id}
      >
        {label}
      </label>
      {horizontal ? (
        <div className={`col-sm-${cols}`}>{children}</div>
      ) : (
        children
      )}
    </div>
  );
};
