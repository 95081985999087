import { FormRenderProps } from 'react-final-form';

import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { TextArea } from '../components/forms/TextArea';
import { EmailTemplate as EmailTemplateType } from './EmailTemplateTypes';

export const EmailTemplateForm: React.FC<FormRenderProps<EmailTemplateType>> =
  ({ handleSubmit, submitting }) => (
    <Form horizontal onSubmit={handleSubmit}>
      <FormLimiter>
        <Input name="name" label="Название" cols={6} />
        <Input name="subject" label="Тема письма" />
        <TextArea name="message" label="Текст письма" rows={15} />
      </FormLimiter>

      <FormSubmitContainer>
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={submitting}
        >
          Сохранить
        </button>
      </FormSubmitContainer>
    </Form>
  );
