import { useCallback, useState } from 'react';

export const useToggle = (
  initialState = false
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [state, setState] = useState(initialState);
  const toggle = useCallback(() => {
    setState((s) => !s);
  }, []);
  return [state, toggle, setState];
};
