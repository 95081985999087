import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { AppUser } from '../appUsers/AppUser';
import { AppUsers } from '../appUsers/AppUsers';
import { config } from '../config';
import { Dashboard } from '../dashboard/Dashboard';
import { SimpleDashboard } from '../dashboard/SimpleDashboard';
import { EmailTemplate } from '../emailTemplates/EmailTemplate';
import { EmailTemplates } from '../emailTemplates/EmailTemplates';
import { Event } from '../events/Event';
import { Events } from '../events/Events';
import { Guest } from '../guests/Guest';
import { Guests } from '../guests/Guests';
import { Staff } from '../staff/Staff';
import { StaffItem } from '../staff/StaffItem';
import { StaffTypeItem } from '../staffTypes/StaffTypeItem';
import { StaffTypes } from '../staffTypes/StaffTypes';
import { User } from '../users/User';
import { Users } from '../users/Users';
import { useAuth } from './AuthProvider';
import { CenterSpinner } from './CenterSpinner';
import { ErrorAlert } from './ErrorAlert';
import { Header } from './Header';
import { Login } from './Login';

export const App = () => {
  const { error, is, loaded, login, loginError, loginRefreshing, user } =
    useAuth();

  if (!loaded) {
    return <CenterSpinner />;
  }

  if (error) {
    return (
      <div className="container-fluid main-container my-3">
        <ErrorAlert>{error}</ErrorAlert>
      </div>
    );
  }

  if (!user) {
    return (
      <Login
        error={loginError}
        onSubmit={login}
        refreshing={loginRefreshing}
        title={config.app.name}
      />
    );
  }

  return (
    <>
      <ToastContainer position="bottom-right" />
      <Route component={Header} />
      <div className="container-fluid main-container pb-3">
        <Switch>
          {is.admin && <Route path="/users" exact component={Users} />}
          {is.admin && <Route path="/users/:id" exact component={User} />}

          {(is.reception || is.usher) && (
            <Route path="/app-users" exact component={AppUsers} />
          )}
          {(is.reception || is.usher) && (
            <Route path="/app-users/:id" exact component={AppUser} />
          )}

          {(is.editor || is.usher) && (
            <Route path="/events" exact component={Events} />
          )}
          {is.editor && <Route path="/events/:id" exact component={Event} />}

          {(is.editor || is.reception) && (
            <Route path="/guests" exact component={Guests} />
          )}
          {(is.editor || is.reception) && (
            <Route path="/guests/:id" exact component={Guest} />
          )}

          {is.editor && <Route path="/staff" exact component={Staff} />}
          {is.editor && <Route path="/staff/:id" exact component={StaffItem} />}

          {is.editor && (
            <Route path="/staff-types" exact component={StaffTypes} />
          )}
          {is.editor && (
            <Route path="/staff-types/:id" exact component={StaffTypeItem} />
          )}

          {is.editor && (
            <Route path="/email-templates" exact component={EmailTemplates} />
          )}
          {is.editor && (
            <Route
              path="/email-templates/:id"
              exact
              component={EmailTemplate}
            />
          )}

          {!is.editor && (is.usher || is.reception) && (
            <Route path="/" exact component={SimpleDashboard} />
          )}
          {is.editor && <Route path="/" exact component={Dashboard} />}
          {is.editor && (
            <Route
              path="/online"
              exact
              render={(props) => <Dashboard online {...props} />}
            />
          )}

          <Route>
            <ErrorAlert>Страница не найдена</ErrorAlert>
          </Route>
        </Switch>
      </div>
    </>
  );
};
