import { dateTimeStr, Gender } from '../../utils/format';

const genderEnding: { [key in Gender]: string } = {
  [Gender.Masculine]: '',
  [Gender.Feminine]: 'а',
  [Gender.Neuter]: 'о',
};

interface CreatorUpdaterProps {
  createdAt?: Date;
  gender?: Gender;
  updatedAt?: Date;
}

export const CreatorUpdater: React.FC<CreatorUpdaterProps> = ({
  createdAt,
  updatedAt,
  gender = Gender.Masculine,
}) => (
  <div id="editor" className="editedBy">
    {createdAt && (
      <span className="label-text">Создан{genderEnding[gender]}: </span>
    )}
    {createdAt && dateTimeStr(createdAt)}
    {createdAt && updatedAt && <br />}
    {updatedAt && (
      <span className="label-text">Изменен{genderEnding[gender]}: </span>
    )}
    {updatedAt && dateTimeStr(updatedAt)}
  </div>
);
