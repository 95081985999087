import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { useItems } from '../services/useItems';
import { StaffType } from '../staff/StaffTypes';

export const StaffTypes: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<StaffType>('/staff-types', history, location);
  const { found, data, onPageClick, onRowClick } = list;

  return (
    <>
      <EntityListHeader
        createUrl="/staff-types/create"
        data={list}
        onPageClick={onPageClick}
        title="Служения"
      />

      {found && (
        <table className="table table-hover table-list">
          <thead>
            <tr>
              <th>Служение</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr key={i.id} onClick={onRowClick}>
                <td>
                  <Link
                    to={(l) => ({ ...l, pathname: `/staff-types/${i.id}` })}
                  >
                    {i.name}
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
