import { useCallback } from 'react';
import { FaEnvelope } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import { api, catchToast } from '../services/api';

interface SendEmailDropdownProps {
  id: number;
}

export const SendEmailDropdown: React.FC<SendEmailDropdownProps> = ({ id }) => {
  const resendEmail = useCallback(() => {
    api
      .post(`/guests/${id}/resend`)
      .then(() => {
        toast.success('Сообщение отправлено');
      })
      .catch(catchToast);
  }, [id]);
  return (
    <>
      <UncontrolledButtonDropdown size="sm" className="ml-2">
        <DropdownToggle caret>
          <FaEnvelope />
        </DropdownToggle>
        <DropdownMenu right>
          <DropdownItem onClick={resendEmail}>
            Отправить письмо ещё раз
          </DropdownItem>
        </DropdownMenu>
      </UncontrolledButtonDropdown>
    </>
  );
};
