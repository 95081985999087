import { Field } from 'react-final-form';

import { FormGroup } from './FormGroup';

interface CheckboxProps {
  cols?: number;
  help?: string;
  label?: string;
  labelCols?: number;
  name: string;
}

export const Checkbox: React.FC<
  CheckboxProps & React.HTMLProps<HTMLInputElement>
> = ({ cols = 10, help, label, labelCols = 2, name, ...rest }) => (
  <Field name={name} type="checkbox">
    {({ input, meta }) => (
      <FormGroup
        cols={cols}
        error={meta.visited && !meta.active ? meta.error : undefined}
        id={name}
        labelCols={labelCols}
      >
        <div className="custom-control custom-checkbox">
          <input
            type="checkbox"
            className="custom-control-input"
            id={name}
            {...input}
          />
          <label className="custom-control-label" htmlFor={name}>
            {label}
          </label>
        </div>
        {help && <small className="form-text text-muted">{help}</small>}
      </FormGroup>
    )}
  </Field>
);
