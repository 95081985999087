import { Moment } from 'moment';

import { CenterSpinner } from '../components/CenterSpinner';
import { ErrorAlert } from '../components/ErrorAlert';
import { useAPI } from '../services/api';
import { ucFirst } from '../utils/format';
import { Chart } from './Chart';

export const SimpleDashboard: React.FC = () => {
  const { data, error, refreshing } = useAPI<{
    totals: { group: number; registered: number; confirmed: number }[];
    byHour: { date: Moment; count: number }[];
  }>('/simple-dashboard');
  return (
    <>
      {refreshing && <CenterSpinner />}
      <ErrorAlert>{error}</ErrorAlert>
      {data && (
        <div className="table-responsive">
          <table className="table table-hover" style={{ width: 'auto' }}>
            <thead>
              <th>Конференция</th>
              <th className="text-right">Зарегистрировано</th>
              <th className="text-right">Подтвердило</th>
              <th className="text-right">Осталось</th>
            </thead>
            <tbody>
              {data.totals.map((i) => (
                <tr key={i.group}>
                  <td>{i.group === 3 ? 'Волонтёры' : `${i.group} конф.`}</td>
                  <td className="text-right tab-nums">{i.registered}</td>
                  <td className="text-right tab-nums">{i.confirmed}</td>
                  <td className="text-right tab-nums">
                    {i.registered - i.confirmed}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      {data && (
        <div className="my-4">
          <Chart
            rows={data.byHour}
            keyProp="date"
            label={(row: { date: Moment; count: number }) =>
              `${ucFirst(row.date.format('dd DD.MM.YYYY HH:00'))}: ${row.count}`
            }
            valueProp="count"
            width="100%"
            height={150}
          />
        </div>
      )}
    </>
  );
};
