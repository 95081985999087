import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { useToggle } from '../services/useToggle';
import { AmountData } from './DashboardTypes';

type AmountProps = AmountData;

export const Amount: React.FC<AmountProps> = ({ byPrice, total }) => {
  const [showAllPrices, toggleShowAllPrices] = useToggle();

  return (
    <div>
      Сумма: {total} ₽{' '}
      <button
        type="button"
        className="btn btn-sm btn-outline-secondary"
        onClick={toggleShowAllPrices}
      >
        {showAllPrices ? <FaCaretUp /> : <FaCaretDown />}
      </button>
      {showAllPrices &&
        byPrice.map((i) => (
          <div key={i.amount} className="tab-nums">
            {i.amount} × {i.count} = {i.total}
          </div>
        ))}
    </div>
  );
};
