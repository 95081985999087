export enum Gender {
  Masculine = 'm',
  Feminine = 'f',
  Neuter = 'n',
}

export interface Person {
  first_name: string;
  middle_name: string;
  last_name: string;
}

export const dateStr = (t: Date): string => {
  const d = `${t.getDate()}`;
  const m = `${t.getMonth() + 1}`;
  return `${d.padStart(2, '0')}.${m.padStart(2, '0')}.${t.getFullYear()}`;
};

export const timeStr = (t: Date): string => {
  const h = `${t.getHours()}`;
  const m = `${t.getMinutes()}`;
  const s = `${t.getSeconds()}`;
  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
};

export const timeStrShort = (t: Date): string => {
  const h = `${t.getHours()}`;
  const m = `${t.getMinutes()}`;
  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}`;
};

export const dateTimeStr = (t: Date): string => {
  const d = `${t.getDate()}`;
  const m = `${t.getMonth() + 1}`;

  const H = `${t.getHours()}`;
  const M = `${t.getMinutes()}`;

  return `${d.padStart(2, '0')}.${m.padStart(
    2,
    '0'
  )}.${t.getFullYear()} в ${H.padStart(2, '0')}:${M.padStart(2, '0')}`;
};

export const dateTimeStrJobs = (t: Date): string => {
  const d = `${t.getDate()}`;
  const m = `${t.getMonth() + 1}`;

  const H = `${t.getHours()}`;
  const M = `${t.getMinutes()}`;
  const Y = `${t.getFullYear()}`.substr(2);

  return `${d.padStart(2, '0')}.${m.padStart(2, '0')}.${Y} ${H.padStart(
    2,
    '0'
  )}:${M.padStart(2, '0')}`;
};

export const niceDuration = (duration: number, showMS = false): string => {
  const sec = Math.floor(duration);
  const ms = duration - sec;
  const s = sec % 60;
  const m = Math.floor(sec / 60) % 60;
  const h = Math.floor(sec / 3600);
  let res = (s < 10 ? ':0' : ':') + s;
  if (showMS) {
    res += `.${ms.toFixed(3).split('.')[1]}`;
  }
  if (h === 0) {
    return m + res;
  }
  return h + (m < 10 ? ':0' : ':') + m + res;
};

export const durationStr = (duration: number): string => {
  const sec = Math.floor(duration);
  const s = `${sec % 60}`;
  const m = `${Math.floor(sec / 60) % 60}`;
  const h = `${Math.floor(sec / 3600)}`;
  return `${h.padStart(2, '0')}:${m.padStart(2, '0')}:${s.padStart(2, '0')}`;
};

export const ucFirst = (s: string): string =>
  s.substr(0, 1).toLocaleUpperCase() + s.substr(1);

export const timecode = (frame: number, fps: number): string => {
  if (fps === 0) {
    return '';
  }
  const t = frame / fps;
  const str = durationStr(t);
  const frames = Math.round((t - Math.floor(t)) * fps);
  return `${str}:${frames >= 10 ? frames : `0${frames}`}`;
};

export const formatBitrate = (br: number): string =>
  Math.round(br / 1e5 / 10).toFixed(1); // .replace('.', ',');

export const shortName = (rawName: string): string => {
  if (!rawName) {
    return '';
  }
  const p = `${rawName.trim()}  `.split(' ');
  const last = p[0];
  const first = p[1];
  const middle = p[2];
  let name = last;
  if (first) {
    name += ` ${first.substr(0, 1)}.`;
    if (middle) {
      name += ` ${middle.substr(0, 1)}.`;
    }
  }
  return name;
};

export const wordAtNumber = (
  n: number,
  w1: string,
  w2: string,
  w5: string
): string => {
  const v = Math.floor(Math.abs(n));
  const r100 = v % 100;
  if (r100 >= 11 && r100 <= 19) {
    return w5;
  }
  const r10 = v % 10;
  switch (r10) {
    case 1:
      return w1;
    case 2:
    case 3:
    case 4:
      return w2;
    default:
      return w5;
  }
};

export const getFullName = (p: Person) =>
  [p.last_name, p.first_name, p.middle_name]
    .map((p) => p.trim())
    .filter(Boolean)
    .join(' ');

export const getFullNameNicer = (p: Person) =>
  [p.first_name, p.middle_name, p.last_name]
    .map((p) => p.trim())
    .filter(Boolean)
    .join(' ');

export const getShortName = (p: Person) =>
  `${p.last_name} ${p.first_name.substr(0, 1)}.${
    p.middle_name ? `${p.middle_name.substr(0, 1)}.` : ''
  }`;

const numberWithUnits = ({
  units,
  digits = 3,
  divider = 0.001,
}: {
  units: string[];
  digits: number;
  divider?: number;
}) => {
  const re = new RegExp(`(\\d{${digits}}|[\\d,]{${digits + 1}})`);
  return (x: number) => {
    const scale = Math.max(Math.floor(Math.log10(x)), 0);
    const label = units[Math.floor(scale / 3)];
    const scale3 = Math.floor(scale / 3);
    const scaled = x / divider ** scale3;
    const m = scaled.toFixed(10).replace('.', ',').match(re);
    const val = m ? m[1] : '?';
    return val + label;
  };
};

export const filesizeStr = numberWithUnits({
  units: [' Б', ' КБ', ' МБ', ' ГБ'],
  digits: 3,
  divider: 1024,
});

export const fixPhone = (phone: string) => {
  const numbers = phone.replace(/[^\d]+/g, '');
  return `+${numbers}`;
};
