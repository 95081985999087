import { FormRenderProps } from 'react-final-form';

import { Checkbox } from '../components/forms/Checkbox';
import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { Radio } from '../components/forms/Radio';
import { useGroupOptions } from '../guests/GuestTypes';
import { Event } from './EventTypes';

export const EventForm: React.FC<FormRenderProps<Event>> = ({
  handleSubmit,
  submitting,
  valid,
}) => {
  const groups = useGroupOptions();
  return (
    <Form horizontal onSubmit={handleSubmit}>
      <FormLimiter>
        <Input label="Название" name="title" maxLength={100} cols={6} />
        <Input label="Место" name="location" maxLength={100} cols={6} />
        <Checkbox label="Включить" name="enabled" />

        <Input label="Начало" name="starts_at" type="datetime-local" cols={3} />
        <Input label="Конец" name="ends_at" type="datetime-local" cols={3} />
        <Radio name="group" label="Конференция" options={groups} />

        <Input
          label="Макс. посетителей"
          name="max_visitors"
          type="number"
          min={0}
          step={1}
          cols={2}
        />
        <Input
          label="Мин. возраст"
          name="min_age"
          type="number"
          min={0}
          step={1}
          cols={2}
        />
      </FormLimiter>

      <FormSubmitContainer>
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={submitting || !valid}
        >
          Сохранить
        </button>
      </FormSubmitContainer>
    </Form>
  );
};
