import { NavLink as Link } from 'react-router-dom';
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavItem,
  NavLink,
  UncontrolledDropdown,
} from 'reactstrap';

import { config } from '../config';
import { useAuth } from './AuthProvider';

export const Header: React.FC = () => {
  const { deImpersonate, is, logout, user } = useAuth();

  return (
    <Navbar dark color="dark" expand="md" className="mb-3">
      <NavbarBrand exact tag={Link} to="/">
        {config.app.name}
      </NavbarBrand>
      <Nav className="mr-auto" navbar>
        {is.editor && (
          <NavItem>
            <NavLink tag={Link} to="/online" className="d-none d-sm-block">
              Онлайн
            </NavLink>
          </NavItem>
        )}
        {(is.editor || is.reception) && (
          <NavItem>
            <NavLink tag={Link} to="/guests" className="d-none d-sm-block">
              Регистрации
            </NavLink>
          </NavItem>
        )}
        {is.editor && (
          <NavItem>
            <NavLink tag={Link} to="/staff" className="d-none d-sm-block">
              Волонтёры
            </NavLink>
          </NavItem>
        )}
        {(is.editor || is.usher) && (
          <NavItem>
            <NavLink tag={Link} to="/events" className="d-none d-sm-block">
              События
            </NavLink>
          </NavItem>
        )}
        {is.editor && (
          <NavItem>
            <NavLink
              tag={Link}
              to="/email-templates"
              className="d-none d-sm-block"
            >
              Письма
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <Nav className="ml-auto" navbar>
        <UncontrolledDropdown nav inNavbar>
          <DropdownToggle nav caret>
            <span className="d-none d-sm-inline">{user && user.name}</span>
          </DropdownToggle>
          <DropdownMenu right>
            {user?.impersonator && (
              <DropdownItem onClick={deImpersonate}>
                Войти как {user.impersonator.name}
              </DropdownItem>
            )}
            {is.admin && (
              <DropdownItem tag={Link} to="/users">
                Пользователи
              </DropdownItem>
            )}
            {(is.reception || is.usher) && (
              <DropdownItem tag={Link} to="/app-users">
                Пользователи приложения
              </DropdownItem>
            )}
            {(is.admin || is.reception) && <DropdownItem divider />}
            <DropdownItem onClick={logout}>Выход</DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </Nav>
    </Navbar>
  );
};
