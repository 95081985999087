import classNames from 'classnames';
import { useCallback } from 'react';
import { BiCheck, BiCheckDouble } from 'react-icons/bi';
import { FaDownload } from 'react-icons/fa';
import { Link, RouteComponentProps } from 'react-router-dom';
import {
  DropdownMenu,
  DropdownToggle,
  UncontrolledButtonDropdown,
} from 'reactstrap';

import { useAuth } from '../components/AuthProvider';
import { EntityListHeader } from '../components/EntityListHeader';
import { useItems } from '../services/useItems';
import { Guest as GuestType } from './GuestTypes';

export const Guests: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const { is } = useAuth();
  const list = useItems<GuestType>('/guests', history, location);
  const { data, found, onPageClick, onRowClick, params, setParams } = list;

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const q = e.target.value || undefined;
      setParams((v: any) => ({ ...v, q, page: q ? undefined : v.page }));
    },
    [setParams]
  );

  const toggle22 = useCallback(() => {
    setParams(({ year, ...v }: any) => (year ? v : { ...v, year: 'next' }));
  }, [setParams]);

  return (
    <>
      <EntityListHeader
        data={list}
        title="Регистрации"
        onPageClick={onPageClick}
        createUrl="/guests/create"
      >
        <input
          className="form-control mr-3 my-2"
          type="search"
          value={params.q || ''}
          onChange={onSearchChange}
          placeholder="Поиск"
          style={{ maxWidth: 250 }}
        />

        {is.editor && (
          <button
            className={classNames(
              'btn',
              params.year === 'next' ? 'btn-primary' : 'btn-outline-primary',
              'ml-auto',
              'mr-2',
              'my-2'
            )}
            type="button"
            onClick={toggle22}
          >
            22
          </button>
        )}
        {is.editor ? (
          <UncontrolledButtonDropdown className="mr-3 my-2">
            <DropdownToggle caret outline>
              <FaDownload />
            </DropdownToggle>
            <DropdownMenu right>
              <a className="dropdown-item" href="/api/guests/download?online=0">
                Все офлайн
              </a>
              <a
                className="dropdown-item"
                href="/api/guests/download?online=0&amp;group=1"
              >
                1 конф.
              </a>
              <a
                className="dropdown-item"
                href="/api/guests/download?online=0&amp;group=2"
              >
                2 конф.
              </a>
              <a
                className="dropdown-item"
                href="/api/guests/download?online=0&amp;group=3"
              >
                Волонтёры
              </a>
              <a className="dropdown-item" href="/api/guests/download?online=1">
                Онлайн
              </a>
              <a
                className="dropdown-item"
                href="/api/guests/download?year=next"
              >
                ЮС22
              </a>
            </DropdownMenu>
          </UncontrolledButtonDropdown>
        ) : (
          <div className="ml-auto"></div>
        )}
      </EntityListHeader>

      {found && (
        <div className="table-responsive">
          <table className="table table-hover table-list">
            <thead>
              <tr>
                <th>№</th>
                <th>Конференция</th>
                <th>Фамилия</th>
                <th>Имя</th>
                <th>Телефон</th>
                <th>Эл. почта</th>
                <th>Город</th>
                <th>Страна</th>
              </tr>
            </thead>
            <tbody>
              {data.map((i) => (
                <tr key={i.id} onClick={onRowClick}>
                  <td>
                    <Link to={(l) => ({ ...l, pathname: `/guests/${i.id}` })}>
                      {i.order_id}
                    </Link>
                    {i.redeemedAt && (
                      <span
                        className="ml-1"
                        title={i.redeemedAt.format('DD.MM.YYYY HH:mm:ss')}
                      >
                        ✅
                      </span>
                    )}
                  </td>
                  <td>
                    {i.online && 'Онлайн'}
                    {i.group === 3 ? 'Волонтёр' : i.group}
                  </td>
                  <td>{i.last_name}</td>
                  <td>{i.first_name}</td>
                  <td className="text-nowrap">{i.phone}</td>
                  <td className="text-nowrap">
                    {i.email}
                    <span className="ml-1 text-primary">
                      {i.sent_to_user_at ? (
                        i.qr_code_shown_at ? (
                          <BiCheckDouble />
                        ) : (
                          <BiCheck />
                        )
                      ) : null}
                    </span>
                  </td>
                  <td>{i.city}</td>
                  <td>{i.country}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};
