import classNames from 'classnames';
import moment from 'moment';
import { useCallback, useMemo } from 'react';
import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { Paginator } from '../components/Paginator';
import { useItems } from '../services/useItems';
import { AppUser, appUserTypeNames } from './AppTypes';

export const AppUsers: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const list = useItems<AppUser>('/app-users', history, location);
  const {
    found,
    data,
    onPageClick,
    onRowClick,
    page,
    pageCount,
    params: { q: search },
    setParams,
  } = list;

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const q = e.target.value || undefined;
      setParams((v: any) => ({ ...v, q, page: q ? undefined : v.page }));
    },
    [setParams]
  );

  const now = useMemo(() => moment(), []);

  const services = useMemo(() => {
    const services = data
      .map((r) => r.redemptions)
      .flat()
      .reduce(
        (all, r) => ({ ...all, [r.id]: r.name }),
        {} as Record<number, string>
      );
    const ids = Object.keys(services).map(Number);
    ids.sort();
    return ids.map((id) => ({ id, name: services[id] }));
  }, [data]);

  return (
    <>
      <EntityListHeader
        addLabel="Добавить"
        createUrl="/app-users/create"
        data={list}
        onPageClick={onPageClick}
        title="Пользователи"
      >
        <input
          className="form-control mr-auto"
          type="search"
          value={search}
          onChange={onSearchChange}
          placeholder="Поиск"
          style={{ maxWidth: 250 }}
        />
      </EntityListHeader>

      {found && (
        <div className="table-responsive">
          <table className="table table-hover table-list">
            <thead>
              <tr>
                <th>Имя</th>
                <th>Регистрация</th>
                <th>Тип</th>
                <th>Статус</th>
                <th>Устройства</th>
                <th className="text-right">Сканирования</th>
                {services.map((s) => (
                  <th className="text-right" key={s.id}>
                    {s.name}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((user) => (
                <tr key={user.id} onClick={onRowClick}>
                  <td>
                    <Link to={`/app-users/${user.id}`}>
                      {user.name ||
                        [user.first_name, user.last_name]
                          .filter(Boolean)
                          .join(' ')}
                    </Link>
                  </td>
                  <td>
                    {user.staff_id && (
                      <>
                        {!user.guest_id && (
                          <span className="badge badge-danger">нет</span>
                        )}
                        {user.guest_id && !user.wristband_at && (
                          <span className="badge badge-secondary">да</span>
                        )}
                        {user.guest_id && user.wristband_at && (
                          <span className="badge badge-success">браслет</span>
                        )}
                      </>
                    )}
                  </td>
                  <td>{appUserTypeNames[user.type]}</td>
                  <td>{user.disabled ? 'Выкл.' : 'Вкл.'}</td>
                  <td>
                    {user.devices.map((d) => (
                      <span
                        className={classNames(
                          'badge',
                          'mr-1',
                          d.last_scan_at &&
                            now.diff(d.last_scan_at, 'minute') < 60
                            ? 'badge-success'
                            : 'badge-secondary'
                        )}
                        key={d.id}
                        style={{ cursor: 'default' }}
                        title={d.last_scan_at?.format('DD.MM.YYYY HH:mm:ss')}
                      >
                        {d.model}
                      </span>
                    ))}
                  </td>
                  <td className="text-right">{user.scans || '—'}</td>
                  {services.map((s) => (
                    <td className="text-right" key={s.id}>
                      {user.redemptions.find((r) => r.id === s.id)?.cnt || '—'}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      )}

      <div className="float-right">
        {pageCount > 1 && (
          <Paginator
            page={page}
            onPageClick={onPageClick}
            pageCount={pageCount}
          />
        )}
      </div>
    </>
  );
};
