import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from 'reactstrap';

import { ErrorAlert } from '../components/ErrorAlert';
import { useAPI } from '../services/api';
import { RedemptionData } from './GuestTypes';

interface RedemptionModalProps {
  id: number;
  show?: boolean;
  title?: string;
  toggle: () => void;
}

export const RedemptionModal: React.FC<RedemptionModalProps> = ({
  children,
  id,
  show,
  title,
  toggle,
}) => {
  const { data, error, refreshing } = useAPI<RedemptionData[]>(
    show ? `/guests/${id}/redemption` : undefined
  );
  return (
    <Modal isOpen={show} toggle={toggle} size="sm">
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        {children}
        <ErrorAlert>{error}</ErrorAlert>
        {refreshing && <Spinner />}
        {data && (
          <div>
            {data.map((r) => (
              <div className="card mt-2" key={r.id}>
                <div className="card-body">
                  <h5 className="card-title">{r.service}</h5>
                  <h6 className="card-subtitle mb-2 text-muted">
                    {r.redeemedAt.format('DD.MM.YYYY HH:mm:ss')}
                  </h6>
                  <p className="card-text">{r.user.name}</p>
                </div>
              </div>
            ))}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <button
          className="btn btn-primary btn-block"
          onClick={toggle}
          type="button"
        >
          Закрыть
        </button>
      </ModalFooter>
    </Modal>
  );
};
