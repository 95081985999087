import { Link, RouteComponentProps } from 'react-router-dom';

import { useAuth } from '../components/AuthProvider';
import { EntityListHeader } from '../components/EntityListHeader';
import { useItems } from '../services/useItems';
import { Event } from './EventTypes';

export const Events: React.FC<RouteComponentProps> = ({
  history,
  location,
}) => {
  const { is } = useAuth();
  const list = useItems<Event>('/events', history, location);
  const { found, data, onPageClick, onRowClick } = list;

  return (
    <>
      <EntityListHeader
        createUrl={!is.editor ? undefined : '/events/create'}
        data={list}
        onPageClick={onPageClick}
        title="События"
      />

      {found && (
        <table className="table table-hover table-list">
          <thead>
            <tr>
              <th>Название</th>
              <th>Место</th>
              <th>Включено</th>
              <th>Начало</th>
              <th>Окончание</th>
              <th>Конф.</th>
              <th className="text-right">Мест</th>
              <th className="text-right">Мин. возраст</th>
              <th className="text-right">Зарег.</th>
              <th className="text-right">Пришло</th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr key={i.id} onClick={onRowClick}>
                <td>
                  {!is.editor ? (
                    i.title
                  ) : (
                    <Link to={(l) => ({ ...l, pathname: `/events/${i.id}` })}>
                      {i.title}
                    </Link>
                  )}
                </td>
                <td>{i.location}</td>
                <td>{i.enabled ? 'Да' : 'Нет'}</td>
                <td>{i.starts_at.format('DD.MM.YYYY HH:mm')}</td>
                <td>{i.ends_at.format('DD.MM.YYYY HH:mm')}</td>
                <td>{i.group}</td>
                <td className="text-right">{i.max_visitors}</td>
                <td className="text-right">{i.min_age}</td>
                <td className="text-right">{i.guests || ''}</td>
                <td className="text-right">{i.visitors || ''}</td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
