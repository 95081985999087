import { useMemo } from 'react';
import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';

import { EntityHeader } from '../components/EntityHeader';
import { ItemHistory } from '../guests/ItemHistory';
import { useItem } from '../services/useItem';
import { EventForm } from './EventForm';
import { Event as EventType } from './EventTypes';
import { validateEvent } from './validateEvent';

export const Event: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<EventType>(match.params.id, '/events', history);
  const { found, item, save } = data;

  const initialValues = useMemo(() => {
    if (!item) {
      return item;
    }
    return {
      ...item,
      starts_at: item.starts_at?.format('YYYY-MM-DDTHH:mm'),
      ends_at: item.ends_at?.format('YYYY-MM-DDTHH:mm'),
    };
  }, [item]);

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={() => 'Событие'}
        listLabel="События"
        listUrl="/events"
      >
        {found && item && <ItemHistory item={item} url="events" />}
      </EntityHeader>

      {found && (
        <Form
          initialValues={initialValues as any}
          onSubmit={save}
          render={EventForm}
          validate={validateEvent}
        />
      )}
    </>
  );
};
