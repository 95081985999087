import { range } from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { useBreakpoint } from '../utils/useBreakpoint';
import './Paginator.css';

const getPageRange = (page: number, pageCount: number, buttonCount = 10) => {
  let first = Math.max(page - Math.floor(buttonCount / 2), 1);
  const last = Math.min(pageCount, first + buttonCount - 1);
  if (last - first < buttonCount - 1) {
    first = Math.max(last - (buttonCount - 1), 1);
  }
  return range(first, last + 1);
};

export const useAltArrows = ({
  onLeftPress,
  onRightPress,
}: {
  onLeftPress: () => void;
  onRightPress: () => void;
}) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      const target = e.target as HTMLElement;
      if ((target && target.tagName === 'INPUT') || (!e.altKey && !e.ctrlKey)) {
        return;
      }
      switch (e.code) {
        case 'ArrowLeft':
          onLeftPress();
          break;
        case 'ArrowRight':
          onRightPress();
          break;
        default:
      }
    };
    window.addEventListener('keydown', listener);
    return () => {
      window.removeEventListener('keydown', listener);
    };
  }, [onLeftPress, onRightPress]);
};

interface PaginatorProps {
  className?: string;
  onPageClick: (page: number) => void;
  page: number;
  pageCount: number;
  style?: React.CSSProperties;
}

export const Paginator: React.FC<PaginatorProps> = ({
  className,
  onPageClick,
  page,
  pageCount,
  style,
}) => {
  const bp = useBreakpoint();

  useAltArrows({
    onLeftPress: useCallback(() => {
      if (page > 1) {
        onPageClick(page - 1);
      }
    }, [page, onPageClick]),
    onRightPress: useCallback(() => {
      if (page < pageCount) {
        onPageClick(page + 1);
      }
    }, [page, pageCount, onPageClick]),
  });

  const pageRange = useMemo(
    () => getPageRange(page, pageCount, bp === 'xs' ? 5 : 10),
    [page, pageCount, bp]
  );
  if (pageCount < 1) return null;

  return (
    <Pagination className={className} style={style}>
      <PaginationItem disabled={page <= 1}>
        <PaginationLink
          style={{ cursor: 'pointer' }}
          previous
          onClick={() => onPageClick(page - 1)}
        />
      </PaginationItem>
      {pageRange.map((p) => (
        <PaginationItem active={p === page} key={p}>
          <PaginationLink
            style={{ cursor: 'pointer' }}
            onClick={() => onPageClick(p)}
          >
            {p}
          </PaginationLink>
        </PaginationItem>
      ))}
      <PaginationItem disabled={page >= pageCount}>
        <PaginationLink
          style={{ cursor: 'pointer' }}
          next
          onClick={() => onPageClick(page + 1)}
        />
      </PaginationItem>
    </Pagination>
  );
};
