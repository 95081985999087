import { Moment } from 'moment';

export const appUserTypeNames: Record<number, string> = {
  0: 'регистрация',
  1: 'служба порядка',
  2: 'СП (выход)',
};

export interface AppUser {
  id: number;
  created_at: Moment;
  updated_at: Moment;
  creator_id: number;
  updater_id: number;
  wristband_at: Moment | null;
  staff_id: number | null;
  guest_id: number | null;
  first_name: string | null;
  last_name: string | null;
  name: string;
  disabled: boolean;
  type: number;
  scans: number;
  redemptions: {
    id: number;
    cnt: number;
    name: string;
  }[];
  devices: {
    id: number;
    last_scan_at: Moment | null;
    model: string;
  }[];
}
