import { Moment } from 'moment';

import { UserRole } from '../components/AuthProvider';

export interface User {
  id: number;
  created_at: Moment;
  updated_at: Moment;
  creator_id: number;
  updater_id: number;
  full_name: string;
  email: string;
  login: string;
  password: string;
  role: UserRole;
  disabled: boolean;
}

export const roles: Record<UserRole, string> = {
  [UserRole.Admin]: 'Администратор',
  [UserRole.Editor]: 'Пользователь',
  [UserRole.Reception]: 'Подтверждение регистрации',
  [UserRole.Usher]: 'Служба порядка',
};
