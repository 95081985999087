import { Guest } from './GuestTypes';

// const reEmail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

export const validateGuest = (values: Guest) => {
  const errors: { [key: string]: string } = {};
  // if (!reEmail.test(values.email)) {
  //   errors.email = 'Неверный формат электронной почты';
  // }
  return errors;
};
