import { useCallback } from 'react';
import { useField, useForm, useFormState } from 'react-final-form';
import { FormGroup, Spinner } from 'reactstrap';

import { Guest } from '../guests/GuestTypes';
import { useAPI } from '../services/api';
import { useChange } from '../services/useChange';
import { List } from '../services/useItems';
import { useToggle } from '../services/useToggle';
import { StaffPerson } from './StaffTypes';

interface GuestInputProps {
  label?: string;
  name: string;
}

export const GuestInput: React.FC<GuestInputProps> = ({ label, name }) => {
  const { input } = useField<
    number | undefined,
    HTMLElement,
    number | undefined
  >(name);

  const { data: guest } = useAPI<Guest>(
    input.value ? `/guests/${input.value}` : undefined
  );

  const { change } = useForm();

  const [showSelect, toggleSelect, setSelect] = useToggle();

  const { values } =
    useFormState<Pick<StaffPerson, 'first_name' | 'last_name'>>();

  const [selectInputValue, onSelectInputChange] = useChange(
    [values.last_name].filter(Boolean).join(' ')
  );

  const { data: search } = useAPI<List<Guest>>(
    showSelect && selectInputValue ? `/guests` : undefined,
    { q: selectInputValue }
  );

  const select = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      toggleSelect();
      input.onChange(id);
      if (!values.first_name && !values.last_name) {
        const row = (search?.data || []).find((r) => r.id === id);
        if (row) {
          change('first_name', row.first_name);
          change('last_name', row.last_name);
        }
      }
    },
    [change, search, values, input, toggleSelect]
  );

  const clear = useCallback(() => {
    setSelect(false);
    input.onChange(undefined);
  }, [input, setSelect]);

  return (
    <FormGroup cols={6} label={label}>
      <div className="form-control-plaintext">
        <div>
          {input.value ? (
            <>
              {guest ? (
                <a
                  href={`/guests/${guest.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {guest.first_name} {guest.last_name} {guest.city}{' '}
                  {guest.order_id}
                </a>
              ) : (
                <Spinner />
              )}
            </>
          ) : (
            'нет'
          )}
          {'  '}
          <button
            className="btn btn-outline-secondary btn-sm"
            onClick={toggleSelect}
            type="button"
          >
            {input.value ? 'изменить' : 'выбрать'}
          </button>
          {'  '}
          {input.value && (
            <button
              className="btn btn-outline-danger btn-sm"
              onClick={clear}
              type="button"
            >
              отвязать
            </button>
          )}
        </div>
        {showSelect && (
          <div className="mt-4">
            <input
              autoFocus
              className="form-control form-control-sm"
              type="text"
              value={selectInputValue}
              onChange={onSelectInputChange}
            />
            {search && (
              <div className="mt-2" style={{ height: 200 }}>
                {search.count === 0 && 'не найдено'}
                {search.count > 0 && (
                  <div
                    className="dropdown-menu"
                    style={{ position: 'static', display: 'block' }}
                  >
                    {search.data.map((i) => (
                      <a
                        className="dropdown-item"
                        href={`/guests/${i.id}`}
                        onClick={select(i.id)}
                      >
                        {i.first_name} {i.last_name} {i.city} {i.order_id}
                      </a>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </FormGroup>
  );
};
