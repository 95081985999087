import { FormRenderProps } from 'react-final-form';

import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { StaffType } from '../staff/StaffTypes';

export const StaffTypeForm: React.FC<FormRenderProps<StaffType>> = ({
  handleSubmit,
  submitting,
  valid,
}) => (
  <Form horizontal onSubmit={handleSubmit}>
    <FormLimiter>
      <Input name="name" maxLength={50} label="Название" cols={4} />
    </FormLimiter>

    <FormSubmitContainer>
      <button
        className="btn btn-lg btn-primary"
        type="submit"
        disabled={submitting || !valid}
      >
        Сохранить
      </button>
    </FormSubmitContainer>
  </Form>
);
