import { useCallback, useEffect, useMemo, useState } from 'react';
import { FaDownload } from 'react-icons/fa';
import { Link, RouteComponentProps } from 'react-router-dom';

import { EntityListHeader } from '../components/EntityListHeader';
import { Guest } from '../guests/GuestTypes';
import { api, catchToast } from '../services/api';
import { useItems } from '../services/useItems';
import { useOrder } from '../services/useOrder';
import { GuestDropdown } from './GuestDropdown';
import { StaffListItem } from './StaffTypes';

export const Staff: React.FC<RouteComponentProps> = ({ history, location }) => {
  const list = useItems<StaffListItem>('/staff', history, location);
  const {
    data: unsorted,
    found,
    onPageClick,
    params,
    refresh,
    setParams,
  } = list;

  const [searchGuest, setSearchGuest] = useState<
    [number, string, string] | undefined
  >();

  useEffect(() => {
    const l = () => setSearchGuest(undefined);
    window.addEventListener('click', l);
    return () => {
      window.removeEventListener('click', l);
    };
  }, []);

  const onSelect = useCallback(
    (id: number) => (g: Guest) => {
      api
        .put(`/staff/${id}/guest/${g.id}`)
        .then(() => {
          setSearchGuest(undefined);
          refresh();
        })
        .catch(catchToast);
    },
    [refresh]
  );

  const onSearchChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const q = e.target.value || undefined;
      setParams((v: any) => ({ ...v, q, page: q ? undefined : v.page }));
    },
    [setParams]
  );

  const onFindGuestClick = useCallback(
    (id: number, lastName: string, firstName: string) =>
      (e: React.MouseEvent) => {
        e.stopPropagation();
        setSearchGuest([id, lastName, firstName]);
      },
    []
  );

  const [order, toggleOrder, getOrder] = useOrder<StaffListItem>([
    'type',
    true,
  ]);

  const data = useMemo(() => {
    if (!unsorted || !order) {
      return unsorted;
    }
    const copy = [...unsorted];
    const col = order[0];
    const asc = order[1];
    copy.sort((a, b) => {
      const av = a[col];
      const bv = b[col];
      if (typeof av === 'string' && typeof bv === 'string') {
        if (asc) {
          return av.localeCompare(bv);
        }
        return bv.localeCompare(av);
      }
      if (typeof av === 'number' || typeof bv === 'number') {
        if (asc) {
          return Number(av || 0) - Number(bv || 0);
        }
        return Number(bv || 0) - Number(av || 0);
      }
      return 0;
    });
    return copy;
  }, [order, unsorted]);

  return (
    <>
      <EntityListHeader
        createUrl="/staff/create"
        data={list}
        onPageClick={onPageClick}
        title="Волонтеры"
      >
        <input
          className="form-control mr-3"
          type="search"
          value={params.q || ''}
          onChange={onSearchChange}
          placeholder="Поиск"
          style={{ maxWidth: 250 }}
        />
        <a
          className="btn btn-outline-secondary ml-auto mr-2"
          href="/api/staff/download"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaDownload />
        </a>
        <Link className="btn btn-outline-secondary mr-3" to="/staff-types">
          Служения
        </Link>
      </EntityListHeader>

      {found && (
        <table
          className="table table-sm table-hover table-list"
          style={{ width: 'auto' }}
        >
          <thead style={{ cursor: 'pointer ' }}>
            <tr>
              <th onClick={toggleOrder('last_name')}>
                Фамилия{getOrder('last_name')}
              </th>
              <th onClick={toggleOrder('first_name')}>
                Имя{getOrder('first_name')}
              </th>
              <th onClick={toggleOrder('type')}>Служение{getOrder('type')}</th>
              <th onClick={toggleOrder('order_id')}>
                Регистрация{getOrder('order_id')}
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((i) => (
              <tr key={(i.guest ? 'g' : '') + (i.id || `g${i.guest_id}`)}>
                <td>
                  {i.id ? (
                    <Link to={(l) => ({ ...l, pathname: `/staff/${i.id}` })}>
                      {i.last_name}
                    </Link>
                  ) : (
                    i.last_name
                  )}
                </td>
                <td>{i.first_name}</td>
                <td>{i.type}</td>
                <td style={{ position: 'relative', whiteSpace: 'nowrap' }}>
                  {i.order_id && i.guest_id && (
                    <a href={`/guests/${i.guest_id}`}>{i.order_id}</a>
                  )}

                  {!i.order_id && i.id && i.last_name && (
                    <span
                      className="mr-2"
                      onClick={onFindGuestClick(
                        i.id,
                        i.last_name,
                        i.first_name
                      )}
                      role="button"
                    >
                      🔎
                    </span>
                  )}

                  {!i.order_id && i.id && searchGuest?.[0] === i.id && (
                    <GuestDropdown
                      last_name={searchGuest[1]}
                      first_name={searchGuest[2]}
                      onSelect={onSelect(i.id)}
                    />
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </>
  );
};
