import { useMemo } from 'react';
import { FormRenderProps } from 'react-final-form';

import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { Select } from '../components/forms/Select';
import { useAPI } from '../services/api';
import { GuestInput } from './GuestInput';
import { StaffPerson } from './StaffTypes';

export const StaffForm: React.FC<FormRenderProps<StaffPerson>> = ({
  handleSubmit,
  submitting,
  valid,
}) => {
  const { data } = useAPI<{ id: number; title: string }[]>(
    '/staff-types/select'
  );
  const types = useMemo(() => [{ id: '', title: '' }, ...(data || [])], [data]);

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <FormLimiter>
        <Input name="first_name" maxLength={50} label="Имя" cols={4} />
        <Input name="last_name" maxLength={50} label="Фамилия" cols={4} />
        <Select
          name="staff_type_id"
          label="Служение"
          cols={4}
          options={types}
        />
        <GuestInput label="Регистрация" name="guest_id" />
      </FormLimiter>

      <FormSubmitContainer>
        <button
          className="btn btn-lg btn-primary"
          type="submit"
          disabled={submitting || !valid}
        >
          Сохранить
        </button>
      </FormSubmitContainer>
    </Form>
  );
};
