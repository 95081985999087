import { useCallback } from 'react';

import { Guest } from '../guests/GuestTypes';
import { useAPI } from '../services/api';

interface GuestDropdownProps {
  first_name: string;
  last_name: string;
  onSelect: (v: Guest) => void;
}

export const GuestDropdown: React.FC<GuestDropdownProps> = ({
  first_name,
  last_name,
  onSelect,
}) => {
  const { data } = useAPI<(Guest & { full_match: boolean })[]>(
    `/staff/guests`,
    {
      first_name,
      last_name,
    }
  );

  const select = useCallback(
    (id: number) => (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();
      const item = data && data.find((i) => i.id === id);
      if (item) {
        onSelect(item);
      }
    },
    [data, onSelect]
  );

  if (!data) {
    return null;
  }

  return (
    <>
      {data.length === 0 && 'не найдено'}
      {data.length > 0 && (
        <div
          className="dropdown-menu"
          style={{
            position: 'absolute',
            display: 'block',
            left: '2rem',
            top: 0,
          }}
        >
          {data.map((i) => (
            <a
              className="dropdown-item"
              href={`/guests/${i.id}`}
              key={i.id}
              onClick={select(i.id)}
            >
              {i.first_name} {i.last_name} {i.city} {i.order_id}{' '}
              {i.full_match && '✅'}
            </a>
          ))}
        </div>
      )}
    </>
  );
};
