import { useMemo } from 'react';
import { FaCaretDown, FaCaretUp } from 'react-icons/fa';

import { useToggle } from '../services/useToggle';
import { DashboardData } from './DashboardTypes';

interface DashboardEventsProps {
  events: DashboardData['events'];
}

export const DashboardEvents: React.FC<DashboardEventsProps> = ({ events }) => {
  const [showAll, toggleShowAll] = useToggle();

  const byHour = useMemo(
    () =>
      events.reduce((all, e) => {
        const dayHour = e.starts_at.format('ddd HH:mm');
        return { ...all, [dayHour]: [...(all[dayHour] || []), e] };
      }, {} as Record<string, DashboardData['events'][number][]>),
    [events]
  );

  return (
    <div>
      <div>
        Мастер-классы{' '}
        <button
          type="button"
          className="btn btn-sm btn-outline-secondary"
          onClick={toggleShowAll}
        >
          {showAll ? <FaCaretUp /> : <FaCaretDown />}
        </button>
      </div>

      {Object.keys(byHour).map((h) => (
        <div key={h}>
          {h}:{' '}
          {showAll ? (
            <div className="mb-2">
              {byHour[h].map((e, i) => (
                <div key={e.id}>
                  {e.title}: {e.guests}
                  {e.visitors && (
                    <small className="text-success"> → {e.visitors}</small>
                  )}
                </div>
              ))}
            </div>
          ) : (
            byHour[h].map((e, i) => (
              <span key={e.id}>
                {e.guests}
                {i < byHour[h].length - 1 && ', '}
              </span>
            ))
          )}
        </div>
      ))}
    </div>
  );
};
