import { StaffPerson } from './StaffTypes';

export const validateStaff = (values: StaffPerson) => {
  const errors: { [key: string]: string } = {};
  if (!values.first_name) {
    errors.first_name = 'Нужно заполнить';
  }
  if (!values.last_name) {
    errors.last_name = 'Нужно заполнить';
  }
  if (!values.staff_type_id) {
    errors.staff_type_id = 'Нужно выбрать';
  }
  return errors;
};
