import classnames from 'classnames';
import React, { useCallback } from 'react';
import { useHistory, useLocation } from 'react-router';

import { CenterSpinner } from '../components/CenterSpinner';
import { useToggle } from '../services/useToggle';
import { ucFirst } from '../utils/format';
import { Amount } from './Amount';
import { Chart } from './Chart';
import { DashboardEvents } from './DashboardEvents';
import { DashboardRegDate } from './DashboardTypes';
import { useDashboard } from './useDashboard';

const MAX_COUNTRIES = 200;
const MAX_CITIES = 20;

interface DashboardProps {
  online?: boolean;
}

export const Dashboard: React.FC<DashboardProps> = ({ online }) => {
  const { search } = useLocation();
  const nextYear = search === '?year=next';
  const redeemed = search === '?redeemed=1';

  const data = useDashboard({ online, nextYear, redeemed });
  const [showAllCities, toggleShowAllCities] = useToggle();

  const { push } = useHistory();
  const on22Change = useCallback<React.ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const checked = e.target.checked;
      push(checked ? '/?year=next' : '/');
    },
    [push]
  );
  const onRedemptionChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (e) => {
      const checked = e.target.checked;
      push(checked ? '/?redeemed=1' : '/');
    },
    [push]
  );

  if (!data) {
    return <CenterSpinner />;
  }

  const {
    accommodation,
    age,
    amount,
    cities,
    countries,
    events,
    free,
    groups,
    regDate,
    regMonth,
    sex,
    staff,
    staffAmount,
    total,
  } = data;

  return (
    <>
      <div className="row">
        <div className="col-md-8 col-sm-12">
          <div className="row">
            <div className="col-md-3 col-6">
              {!online && (
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="youth22"
                    checked={nextYear}
                    onChange={on22Change}
                  />
                  <label className="custom-control-label" htmlFor="youth22">
                    ЮС22: {data.nextYear}
                  </label>
                </div>
              )}

              <div>Регистраций: {total} </div>
              <Amount {...amount} />
              <div>Без оплаты: {free}</div>
            </div>
            <div className="col-md-3 col-6">
              {!online && (
                <div className="custom-control custom-switch">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id="redeemed"
                    checked={redeemed}
                    onChange={onRedemptionChange}
                  />
                  <label className="custom-control-label" htmlFor="redeemed">
                    Подтвердили: {data.redemptions}
                  </label>
                </div>
              )}
              {!online && (
                <div>
                  Конф. 1: {groups[1]}, 2: {groups[2]}
                </div>
              )}
              <div>
                М: {sex.male}, Ж: {sex.female}
              </div>
              {!online && <div>Расселение: {accommodation}</div>}
            </div>
            <div className="col-md-3 col-6">
              {!online && !nextYear && (
                <>
                  <div>
                    Волонтёры: {staff.guests}
                    {!redeemed && <small>/{staff.listTotal}</small>}
                    {!redeemed && staff.guests !== staff.linked && (
                      <span
                        className="small ml-1 text-danger"
                        title="Привязаны к регистрациям"
                        style={{ cursor: 'default' }}
                      >
                        {staff.linked}
                      </span>
                    )}
                  </div>
                  <Amount {...staffAmount} />
                </>
              )}
            </div>
            <div className="col-md-3 col-6">
              {!online && !redeemed && !nextYear && (
                <DashboardEvents events={events} />
              )}
            </div>
          </div>

          <div>
            <div className="mt-3">
              <div>Возраст</div>
              <Chart
                rows={age}
                keyProp="age"
                valueProp="count"
                width="100%"
                height={150}
                showLabels
              />
            </div>

            <div className="mt-3">
              <div>{redeemed ? 'Подтверждений' : 'Регистраций'} в день</div>
              <Chart
                rows={regDate}
                keyProp="date"
                label={(row: DashboardRegDate) =>
                  `${ucFirst(row.date.format('dd DD.MM.YYYY'))}: ${
                    row.count
                  } / ${row.total}`
                }
                valueProp="count"
                width="100%"
                height={150}
              />
            </div>

            {!redeemed && (
              <div className="mt-3">
                <div>Регистрации по месяцам</div>
                <Chart
                  rows={regMonth}
                  keyProp="date"
                  label={(row: DashboardRegDate) =>
                    `${ucFirst(row.date.format('MMMM YYYY'))}: ${row.count} / ${
                      row.total
                    }`
                  }
                  valueProp="count"
                  width="100%"
                  height={150}
                />
              </div>
            )}
          </div>
        </div>
        <div className="col-md-4 col-sm12">
          <div className="row">
            <div className="col">
              <h4>Страны: {countries.length}</h4>
              {countries.slice(0, MAX_COUNTRIES).map(({ name, count }) => (
                <div key={name}>
                  {name}: {count}
                </div>
              ))}
              {countries.length > MAX_COUNTRIES &&
                `+${countries.length - MAX_COUNTRIES}`}
            </div>
            <div className="col">
              <h4>Города: {data.cities.length}</h4>
              {cities.slice(0, MAX_CITIES).map(({ name, count }) => (
                <div key={name}>
                  {name}: {count}
                </div>
              ))}
              {cities.length > MAX_CITIES && (
                <>
                  {showAllCities &&
                    cities.slice(MAX_CITIES).map(({ name, count }) => (
                      <div key={name}>
                        {name}: {count}
                      </div>
                    ))}
                  <button
                    className={classnames(
                      'btn',
                      'btn-sm',
                      'btn-outline-secondary',
                      'mb-3',
                      'mt-1',
                      showAllCities && 'active'
                    )}
                    type="button"
                    onClick={toggleShowAllCities}
                  >
                    {showAllCities ? '−' : '+'}
                    {cities.length - MAX_CITIES}
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
