import { AxiosError } from 'axios';
import {
  createContext,
  useContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import { api } from '../services/api';

export enum UserRole {
  Admin = 'admin',
  Editor = 'editor',
  Reception = 'reception',
  Usher = 'usher',
}

export interface AuthUser {
  id: number;
  avatar: string;
  name: string;
  role: UserRole;
  impersonator?: {
    name: string;
  };
}

export interface AuthContextData {
  deImpersonate: () => void;
  error?: string;
  impersonate: (id: number) => void;
  is: { admin: boolean; editor: boolean; reception: boolean; usher: boolean };
  loaded: boolean;
  login: (username: string, password: string) => void;
  loginError?: string;
  loginRefreshing: boolean;
  logout: () => void;
  refreshAuth: () => void;
  refreshing: boolean;
  setUser: (user: AuthUser) => void;
  user: AuthUser | undefined;
}

const initialAuthData: AuthContextData = {
  deImpersonate: () => {},
  impersonate: () => {},
  is: { admin: false, editor: false, reception: false, usher: false },
  loaded: false,
  login: () => {},
  loginRefreshing: false,
  logout: () => {},
  refreshAuth: () => {},
  refreshing: false,
  setUser: () => {},
  user: undefined,
};

export const AuthContext = createContext<AuthContextData>(initialAuthData);

export const useAuth = (): AuthContextData => useContext(AuthContext);

export const AuthProvider: React.FC = ({ children }) => {
  let history = useHistory();

  const [user, setUser] = useState<AuthUser | undefined>(undefined);
  const [refreshing, setRefreshing] = useState<boolean>(true);
  const [loginRefreshing, setLoginRefreshing] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>(undefined);
  const [loginError, setLoginError] = useState<string | undefined>(undefined);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshAuth = useCallback(() => {
    setRefreshCounter((v) => v + 1);
  }, []);

  useEffect(() => {
    setTimeout(
      () => {
        api
          .get<AuthUser | undefined>('/auth')
          .then((res) => {
            setUser(res.data);
          })
          .catch((err: AxiosError) => {
            if (err.response && err.response.status === 401) {
              setUser(undefined);
            } else {
              setError(err.message);
            }
          })
          .then(() => {
            setRefreshing(false);
          });
      },
      process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 300 : 0
    );
  }, [refreshCounter]);

  const login = useCallback((login: string, password: string) => {
    setLoginError(undefined);
    setLoginRefreshing(true);
    api
      .post<AuthUser>('/auth/login', { login, password })
      .then((res) => {
        setLoginRefreshing(false);
        setUser(res.data);
      })
      .catch((err: AxiosError) => {
        if (err.response && err.response.status === 403) {
          setLoginError('Неверный логин или пароль');
        } else {
          setLoginError(
            err.response && err.response.data && err.response.data.error
          );
        }
        setLoginRefreshing(false);
      });
  }, []);

  const logout = useCallback(() => {
    setRefreshing(true);
    api
      .post('/auth/logout')
      .then(() => {
        refreshAuth();
      })
      .catch((err) => {
        toast.error(err.message);
      });
    history.push('/');
  }, [history, refreshAuth]);

  const impersonate = useCallback(
    (id) => {
      api
        .post<AuthUser>(`/auth/impersonate/${id}`)
        .then((res) => {
          setUser(res.data);
          history.push('/');
        })
        .catch((err: AxiosError) => {
          toast.error(err.message);
        });
    },
    [history]
  );

  const deImpersonate = useCallback(() => {
    api
      .delete<AuthUser>('/auth/impersonate')
      .then((res) => {
        setUser(res.data);
      })
      .catch((err: AxiosError) => {
        toast.error(err.message);
      });
  }, []);
  return (
    <AuthContext.Provider
      value={{
        deImpersonate,
        error,
        impersonate,
        is: {
          admin: user?.role === UserRole.Admin,
          editor:
            user?.role === UserRole.Admin || user?.role === UserRole.Editor,
          reception:
            user?.role === UserRole.Admin || user?.role === UserRole.Reception,
          usher: user?.role === UserRole.Admin || user?.role === UserRole.Usher,
        },
        loaded: !refreshing,
        login,
        loginError,
        loginRefreshing,
        logout,
        refreshAuth,
        refreshing,
        setUser,
        user,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
