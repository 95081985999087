import { Form } from 'react-final-form';
import { RouteComponentProps } from 'react-router-dom';

import { EntityHeader } from '../components/EntityHeader';
import { useItem } from '../services/useItem';
import { EmailTemplateForm } from './EmailTemplateForm';
import { EmailTemplate as EmailTemplateType } from './EmailTemplateTypes';

export const EmailTemplate: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<EmailTemplateType>(
    match.params.id,
    '/email-templates',
    history
  );
  const { found, item, save } = data;

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={() => 'Шаблон письма'}
        listLabel="Шаблоны писем"
        listUrl="/email-templates"
      />

      {found && (
        <Form initialValues={item} onSubmit={save} render={EmailTemplateForm} />
      )}
    </>
  );
};
