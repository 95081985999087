import { useCallback } from 'react';
import { Field, FormRenderProps } from 'react-final-form';

import { Checkbox } from '../components/forms/Checkbox';
import { DateInput } from '../components/forms/DateInput';
import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { Radio } from '../components/forms/Radio';
import { Guest, useGroupOptions } from './GuestTypes';

export const GuestForm: React.FC<
  FormRenderProps<Guest & { transfer: boolean }>
> = ({ handleSubmit, submitting, valid, form: { change }, values }) => {
  const creating = !values.id;

  const setTransfer = useCallback(
    (v: boolean) => () => {
      change('transfer', v);
    },
    [change]
  );

  const groups = useGroupOptions();

  return (
    <Form horizontal onSubmit={handleSubmit}>
      <FormLimiter>
        <Input name="first_name" maxLength={50} label="Имя" cols={4} />
        <Input name="last_name" maxLength={50} label="Фамилия" cols={4} />

        <Radio name="group" label="Конференция" options={groups} />

        <div className="form-group row">
          <label htmlFor="born_at" className="col-lg-2 col-form-label">
            Дата рождения
          </label>
          <div className="col-lg-4">
            <Field name="born_at">
              {({ input }) => <DateInput {...input} />}
            </Field>
          </div>
        </div>

        <Radio
          name="sex"
          label="Пол"
          options={[
            { value: '2', label: 'мужской' },
            { value: '1', label: 'женский' },
          ]}
        />

        <Input name="phone" maxLength={20} label="Телефон" cols={4} />
        <Input name="email" maxLength={50} label="Эл. почта" cols={4} />
        <Input name="country" maxLength={50} label="Страна" cols={4} />
        <Input name="city" maxLength={50} label="Город" cols={4} />
        <Input name="church" maxLength={50} label="Церковь" />
        <Checkbox name="accommodation" label="Расселение" />
      </FormLimiter>

      {!values.transferred_to_next_year_at && (
        <FormSubmitContainer>
          <button
            className="btn btn-lg btn-primary mr-5"
            type="submit"
            onClick={setTransfer(false)}
            disabled={submitting || !valid}
          >
            {creating ? 'Создать' : 'Сохранить'}
          </button>
          {!creating && (
            <button
              className="btn btn-lg btn-warning"
              type="submit"
              onClick={setTransfer(true)}
              disabled={submitting || !valid}
            >
              Сохранить и выдать новый QR-код
            </button>
          )}
          {!creating && (
            <div className="mt-4 text-muted small">
              Выдавайте новый QR-код, когда один человек передает регистрацию
              другому. Старый код перестанет работать.
            </div>
          )}
        </FormSubmitContainer>
      )}
    </Form>
  );
};
