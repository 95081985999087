import { useMemo } from 'react';
import { Form as FinalForm } from 'react-final-form';
import { FaQrcode } from 'react-icons/fa';
import { RouteComponentProps } from 'react-router-dom';

import { useAuth } from '../components/AuthProvider';
import { EntityHeader } from '../components/EntityHeader';
import { Checkbox } from '../components/forms/Checkbox';
import { Form } from '../components/forms/Form';
import { FormLimiter } from '../components/forms/FormLimiter';
import { FormSubmitContainer } from '../components/forms/FormSubmitContainer';
import { Input } from '../components/forms/Input';
import { Radio } from '../components/forms/Radio';
import { useItem } from '../services/useItem';
import { useToggle } from '../services/useToggle';
import { AppUser as AppUserType } from './AppTypes';
import { QrCodeModal } from './QrCodeModal';

export const validateAppUser = (values: AppUserType) => {
  const errors: Partial<Record<keyof AppUserType, string>> = {};

  if (!values.staff_id && !values.name) {
    errors.name = 'Нужно заполнить';
  }

  if (typeof values.type !== 'number') {
    errors.type = 'Нужно выбрать';
  }

  return errors;
};

export const AppUser: React.FC<RouteComponentProps<{ id: string }>> = ({
  history,
  match,
}) => {
  const data = useItem<AppUserType>(match.params.id, '/app-users', history);
  const { creating, found, item, save } = data;
  const [showCode, toggleCode] = useToggle(false);

  const {
    is: { reception, usher },
  } = useAuth();

  const appUserTypes = useMemo(
    () => [
      ...(reception ? [{ value: '0', label: 'регистрация' }] : []),
      ...(usher ? [{ value: '1', label: 'служба порядка' }] : []),
    ],
    [reception, usher]
  );

  return (
    <>
      <EntityHeader
        data={data}
        getTitle={(i) => i.name}
        listLabel="Пользователи"
        listUrl="/app-users"
      >
        {!creating && (
          <button
            className="btn btn-primary btn-sm"
            onClick={toggleCode}
            type="button"
          >
            <FaQrcode />
          </button>
        )}
      </EntityHeader>

      <QrCodeModal
        title="Активация приложения"
        show={showCode}
        toggle={toggleCode}
        url={item && `/api/app-users/${item.id}/register-device-qr-code.svg`}
      >
        <div className="mb-3">
          {item?.name}
          {item?.first_name} {item?.last_name}
        </div>
      </QrCodeModal>

      {found && (
        <FinalForm
          initialValues={item}
          onSubmit={save}
          validate={validateAppUser}
        >
          {({ handleSubmit, submitting }) => (
            <Form horizontal onSubmit={handleSubmit}>
              <FormLimiter>
                {item?.staff_id ? (
                  <div className="mb-3 row">
                    <label className="col-sm-2 col-form-label">Имя</label>
                    <div className="col-sm-10">
                      <div className="form-control-plaintext">
                        {item.first_name} {item.last_name}
                      </div>
                    </div>
                  </div>
                ) : (
                  <Input name="name" label="Имя" cols={6} />
                )}
                <Checkbox name="disabled" label="Заблокирован" />
                <Radio name="type" label="Тип" options={appUserTypes} />
              </FormLimiter>

              <FormSubmitContainer>
                <button
                  className="btn btn-lg btn-primary"
                  type="submit"
                  disabled={submitting}
                >
                  Сохранить
                </button>
              </FormSubmitContainer>
            </Form>
          )}
        </FinalForm>
      )}
    </>
  );
};
