import moment, { isMoment, Moment } from 'moment';
import { useCallback, useMemo, useState } from 'react';
import { FaHistory } from 'react-icons/fa';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

import { ErrorAlert } from '../components/ErrorAlert';
import { useAPI } from '../services/api';
import { guestColumnNames, HistoryData, Sex } from './GuestTypes';

interface ItemHistoryProps {
  item: { id: number; updated_at: Moment } & Record<string, any>;
  url: string;
}

const guestColToString = (
  col: string,
  value: string | Moment | number | null | undefined | boolean
): string => {
  if (col === 'born_at') {
    return (
      isMoment(value) ? value : moment(value as string, 'DD.MM.YYYY HH:mm:ss')
    ).format('DD.MM.YYYY');
  }
  if (isMoment(value)) {
    return value.format('DD.MM.YYYY HH:mm:ss');
  }
  if (col === 'sex') {
    return value === Sex.Male ? 'Мужской' : 'Женский';
  }
  return `${value}`;
};

export const ItemHistory: React.FC<ItemHistoryProps> = ({ item, url }) => {
  const { data, error } = useAPI<HistoryData[]>(
    `/${url}/${item.id || 0}/history?h=${item.updated_at?.unix()}`
  );
  const [isOpen, setOpen] = useState(false);
  const toggle = useCallback(() => setOpen((v) => !v), []);

  const h = useMemo(() => {
    const g: Record<string, any> = { ...item };
    const d: (HistoryData & { next?: string })[] = [...(data || [])];
    d.reverse();
    for (let i = 0; i < d.length; i++) {
      const col = d[i].column;
      d[i].next = g[col];
      g[col] = d[i].value;
    }
    return d;
  }, [item, data]);

  return (
    <>
      <div className="mr-2">
        <button
          className="btn btn-secondary btn-sm"
          disabled={h.length === 0}
          onClick={toggle}
          title={h.length === 0 ? 'Нет изменений' : undefined}
          type="button"
        >
          <FaHistory />
        </button>
      </div>

      <ErrorAlert>{error}</ErrorAlert>

      <Modal isOpen={isOpen} toggle={toggle} size="lg">
        <ModalHeader toggle={toggle}>История изменений</ModalHeader>
        <ModalBody>
          <table className="table table-hover">
            <thead>
              <tr>
                <th>Пользователь</th>
                <th>Дата и время</th>
                <th>Поле</th>
                <th>Было</th>
                <th>Стало</th>
              </tr>
            </thead>
            <tbody>
              {h.map((r, i) => (
                <tr key={r.id}>
                  <td>{r.user === h[i - 1]?.user ? null : r.user}</td>
                  <td>
                    {h[i - 1] && r.updated_at.isSame(h[i - 1].updated_at)
                      ? null
                      : r.updated_at.format('DD.MM.YYYY HH:mm:ss')}
                  </td>
                  <td>{guestColumnNames[r.column]}</td>
                  <td>{guestColToString(r.column, r.value)}</td>
                  <td>{guestColToString(r.column, r.next)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </ModalBody>
        <ModalFooter>
          <button type="button" className="btn btn-secondary" onClick={toggle}>
            Закрыть
          </button>
        </ModalFooter>
      </Modal>
    </>
  );
};
